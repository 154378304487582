<template>
    <kdx-modal-frame :value="value" title="商品备注" :isOk="false" @on-cancel="handleCancel">
        <div class="content">
            <div class="remark-content" v-html="data"></div>
        </div>
    </kdx-modal-frame>
</template>

<script>
export default {
    name: "FastImport",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        content: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            data: ''
        };
    },
    computed: {},
    watch: {
        content() {
            this.handleContent();
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        handleCancel() {
            this.$emit('input', false);
        },
        handleContent() {
            let reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%|#)+)/g;
            this.data = this.content.replace(reg, "<a href='$1$2' target='_blank'>$1$2</a>");
        }
    },
};
</script>

<style lang="scss" scoped>
.content {
    padding: 10px 20px 60px;

    .remark-content {
        line-height: 24px;
        white-space: pre-wrap;
        word-break: break-all;
    }
}
</style>