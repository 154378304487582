<template>
	<div>
		<!--订单状态-->
		<Row class="order-information" type="flex">
			<!-- 当前订单状态 -->
			<Col span="8" class="status-col">
			<!--待付款-->
			<!-- <template v-if="data.status === '0'">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title">
					<img :src="image.payment" alt="">
					<span class="title">待付款</span>
				</p>

				<Button type="primary" class="status-btn" @click="confirmPayment">
					确认付款
				</Button>
				<kdx-hint-text>
					友情提示：如果无法进行发货，请及时联系买家
				</kdx-hint-text>
				<div class="footer-btn">
					<Button class="default-primary" @click="changePrice">
						订单改价
					</Button>
					<Button class="default-primary"
						v-if="data.dispatch_type !== '20' && goods_type != '2' && goods_type != '5' && goods_type != '6' && data.order_type != '40'"
						@click="editReceiveInfo">
						修改收货信息
					</Button>
					<Button class="default-primary" @click="closeOrder">
						关闭订单
					</Button>
				</div>

			</template> -->
			<!--待发货-->
			<template v-if="data.isSend == 0">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title">
					<img :src="image.send" alt="">
					<span class="title">待发货</span>
				</p>

				<template>
					<Button type="primary" @click="sendGoods" class="status-btn">确认发货</Button>
					<kdx-hint-text>
						友情提示：如果无法进行发货，请及时联系买家
					</kdx-hint-text>
				</template>
				<!-- <div class="footer-btn">
					<Button v-if="data.goods_info[0].type != '2' && data.order_type != '40'" class="default-primary"
						@click="editReceiveInfo">
						修改收货信息
					</Button>
					<Button class="default-primary" @click="generalRefund" v-if="data.pay_type != '3' && data.pay_type != '1'">
						退款
					</Button>
				</div> -->
			</template>
			<!--部分发货-->
			<!-- <template v-else-if="data.status === '11'">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title">
					<img :src="image.send" alt="">
					<span class="title">部分发货</span>
				</p>
				<Button type="primary" class="status-btn" @click="sendGoods">确认发货</Button>
				<kdx-hint-text>
					友情提示：如果无法进行发货，请及时联系买家
				</kdx-hint-text>
				<div class="footer-btn">
					<Button class="default-primary" @click="editReceiveInfo">
						修改收货信息
					</Button>
					<Button class="default-primary" @click="generalRefund" v-if="data.pay_type != '3' && data.pay_type != '1'">
						退款
					</Button>
				</div>
			</template> -->
			<!--待收货-->
			<!-- <template v-else-if="data.status === '20'">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title">
					<img :src="image.put" alt="">
					<span class="title">{{ '待收货' }}</span>
				</p>
				<Button type="primary" class="status-btn" @click="confirmTakeGoods">
					确认收货
				</Button>
				<kdx-hint-text>
					友情提示：如果无法进行发货，请及时联系买家
				</kdx-hint-text>

				<div class="footer-btn">
					<div style="display: inline-block">
						<Button class="default-primary" @click="cancelSendGoods"
							v-if="(goods_type === '0' || goods_type === '7') && data.scene != '20'">
							取消发货
						</Button>
						<Button class="default-primary" @click="editReceiveInfo"
							v-if="(goods_type === '0' || goods_type === '7') && data.order_type != '40'">
							修改收货信息
						</Button>
						<Button class="default-primary" @click="generalRefund"
							v-if="data.pay_type == '3' || goods_type === '1'">退款</Button>
					</div>
					<template>
						<Button class="default-primary" @click="generalRefund" v-if="data.scene != '20'">退款</Button>
					</template>
				</div>
			</template> -->
			<!--交易完成-->
			<template v-if="data.isSend == 1">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title finish">
					<span class="iconfont icon-duihao2"></span>
					<span class="title">交易完成</span>
				</p>
			</template>
			<!--交易关闭-->
			<!-- <template v-else-if="data.status === '-1'">
				<p class="title" style="padding-left: 0">
					当前订单状态
				</p>
				<p class="big-title close">
					<span class="iconfont icon-del"></span>
					<span class="title">交易关闭</span>
				</p>
				<p class="time-out word-break">
					{{ data.cancel_reason || data.refund_reason || '' }}
				</p>
			</template> -->


			<!-- <p class="title" style="padding: 30px 0 0 0;">打印</p>
            <div class="footer-btn">
                <Button class="default-primary" @click="jumpEOrder"
                    v-if="hasOrderSheet(data) && !data.not_show_express">电子面单</Button>
                <Button class="default-primary" @click="handlePrinter">小票</Button>
                <Button class="default-primary" @click="jumpEOrder"
                    v-if="hasOrderSheet(data) && !data.not_show_express">发货单</Button>
            </div> -->
			</Col>
			<!-- 发货信息 -->
			<Col span="8">
			<p class="title">
				发货信息
			</p>
			<Form :label-width="100">
				<FormItem label="配送方式：">
					<div class="dispatch-type flex">
						<template v-if="data.isSend == 0">
							<span class="text">-</span>
						</template>
						<template v-else-if="data.shopType == 1">
							<span class="text">快递</span>
						</template>
						<template v-else-if="data.shopType == 2">
							<span class="text">自动发货（虚拟物品）</span>
						</template>
						<template v-else>-</template>
					</div>
				</FormItem>
				<!-- 配送、自提时间 -->
				<!-- <FormItem :label="`${getDeliverName}：`" v-if="getDeliverName && getDeliverTime">
					{{ getDeliverTime }}
				</FormItem> -->
				<!-- 虚拟商品不显示 -->
				<FormItem label="收货人姓名：">
					<template v-if="data.name">
						{{ data.name }}
					</template>
					<template v-else>
						-
					</template>
				</FormItem>
				<!-- 虚拟商品不显示 -->
				<FormItem label="联系方式：">
					<template v-if="data.mobile">
						<span>{{ data.mobile }}</span>
						<kdx-copy-text :text="data.mobile">
							<Button type="text" size="small">复制</Button>
						</kdx-copy-text>
					</template>
					<template v-else>
						-
					</template>
				</FormItem>
				<!-- 虚拟商品不显示 -->
				<FormItem label="收货地址：">
					<div class="paragraph">
						<template v-if="data.address">
							<span>{{ data.address }}</span>
							<kdx-copy-text :text="data.address">
								<Button type="text" size="small">复制</Button>
							</kdx-copy-text>
						</template>
						<template v-else>-</template>
					</div>
				</FormItem>
				<!-- 虚拟商品不显示 -->
				<FormItem label="物流单号：" v-if="data.shopType == 1">
					<div class="paragraph">
						<template v-if="data.isSend == 1">
							<ul class="logistics">
								<li>
									<div class="name">{{ data.logisticsNme }}</div>
									<div class="number">{{ data.number }}</div>
									<kdx-copy-text :text="data.number">
										<Button type="text" size="small">复制</Button>
									</kdx-copy-text>
								</li>
							</ul>
						</template>
						<template v-else>-</template>
					</div>
				</FormItem>
				<!-- <FormItem label="发货内容：" v-if="goods_type === '1' && auto_deliver_content">
	
					<div class="paragraph">
						<template>
							<span>{{ auto_deliver_content }}</span>
							<kdx-copy-text :text="auto_deliver_content">
								<Button type="text" size="small">复制</Button>
							</kdx-copy-text>
						</template>
					</div>
				</FormItem>
				
				<FormItem label="发货内容：" v-if="goods_type === '2'">
					<div class="paragraph">
						<template>
							<div v-if="virtual_setting.config">
								<span class="goAddress" @click="deliveryContent">查看发货内容</span>
							</div>
							<span v-else>-</span>
						</template>
					</div>
				</FormItem>
				<FormItem
					:label="virtual_setting.use_address && virtual_setting.use_address != '0' ? virtual_setting.use_address_title : '使用地址：'"
					v-if="goods_type === '2'" class="overText">
					<div class="paragraph">
						<template v-if="virtual_setting.use_address != '0'">
							<span>： {{ virtual_setting.use_address_address || '-' }}</span>
							<span class="goAddress" @click="goAddress(virtual_setting.use_address_address)">查看地址</span>
						</template>
						<template v-else>
							<span>-</span>
						</template>
					</div>
				</FormItem>
				<FormItem label="邮箱地址：" v-if="goods_type === '2'">
					<div class="paragraph">
						<template>
							<span>{{ data.to_mailer || '-' }}</span>
						</template>
					</div>
				</FormItem>
				<FormItem label="买家备注：">
					<span class="paragraph">
						<template v-if="data.buyer_remark">
							{{ data.buyer_remark }}
						</template>
						<template v-else>
							-
						</template>
					</span>
				</FormItem>
				<FormItem label="发票信息：">
					<template v-if="isInvoiceInfo">
						<Button type="text" @click="packageInvoiceInfo">查看发票信息</Button>
						<span class="no-invoice" v-if="data.invoice_info.status == '0'">
							<span>未开票</span>
						</span>
					</template>
					<template v-else>
						无
					</template>
				</FormItem> -->

			</Form>
			</Col>
			<Col span="8">
			<p class="title">
				订单信息
			</p>
			<Form :label-width="100">

				<FormItem label="订单编号：" class="order-no">
					<div class="flex">
						<template v-if="data.orderNum">
							<span>{{ data.orderNum }}</span>
							<kdx-copy-text :text="data.orderNum">
								<Button type="text" size="small">复制</Button>
							</kdx-copy-text>
						</template>
						<template v-else>-</template>
					</div>
				</FormItem>
				
				<!-- <FormItem label="交易单号：">
					<template v-if="data.trade_no && data.trade_no !== '0'">
						<span>{{ data.trade_no }}</span>
						<kdx-copy-text type="qrCode" :text="data.trade_no">
							<Button type="text" size="small">复制</Button>
						</kdx-copy-text>
					</template>
					<template v-else>
						-
					</template>
				</FormItem> -->

				<!-- <FormItem label="外部交易单号：">
					
					<template v-if="data.out_trade_no && data.out_trade_no !== '0'">
						<span>{{ data.out_trade_no }}</span>
						<kdx-copy-text type="qrCode" :text="data.out_trade_no">
							<Button type="text" size="small">复制</Button>
						</kdx-copy-text>
					</template>
					<template v-else>
						-
					</template>
				</FormItem> -->

				<!-- <FormItem label="商家备注：" v-if="data.seller_remark">
					<span class="paragraph">
						{{ data.seller_remark }}
					</span>
				</FormItem> -->
			</Form>
			</Col>
		</Row>
		<!--确认发货-->
		<send-goods ref="send_goods" :id="modal.id" :item="data" :dispatch_type="modal.dispatch_type"
			@on-refresh="handleRefresh"></send-goods>
		<!--修改收货信息-->
		<edit-receiving-goods ref="edit_receiving_goods" :id="modal.id" :dispatch_type="modal.dispatch_type"
			@on-refresh="handleRefresh"></edit-receiving-goods>
		<!--订单改价-->
		<change-price ref="change_price" :id="modal.id" @on-refresh="handleRefresh"></change-price>
		<!--关闭订单-->
		<close-order ref="close_order" :visible="modal.closeOrder" :id="modal.id" :title="modal.title"
			@on-refresh="handleRefresh"></close-order>
		<!--分包裹---取消发货-->
		<cancel-send-goods ref="cancel_send_goods" :id="modal.id" @on-refresh="handleRefresh"></cancel-send-goods>
		<!--发票-->
		<invoice-info ref="invoice_info" :data="invoiceInfo.data" :price="invoiceInfo.price" :id="modal.id"
			@on-refresh="handleRefresh"></invoice-info>
		<!--退款-->
		<refund-modal ref="refund_modal" :id="modal.id" @on-refresh="handleRefresh"></refund-modal>
		<!-- 小票打印 -->
		<!-- <kdx-modal-frame v-model="printerModal" title="请选择相关信息" :width="610" @on-cancel="printerCancel" @on-ok="printerOk">
            <div class="printer-modal">
                <Form ref="form" :label-width="120" :model="printerModelData" :rules="rules" @submit.native.prevent>
                    <FormItem label="打印机：" prop="printer_id">
                        <Select class="width-340" v-model="printerModelData.printer_id" placeholder="请选择打印机">
                            <Option v-for="item in printerList" :key="item.id" :value="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="打印模板：" prop="template_id">
                        <Select class="width-340" v-model="printerModelData.template_id" placeholder="请选择打印模板">
                            <Option v-for="item in templateList" :key="item.id" :value="item.id">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                </Form>
            </div>
        </kdx-modal-frame> -->
	</div>
</template>

<script>
import EditReceivingGoods from '@/views/order/components/EditReceiveGoods/index';
import SendGoods from '@/views/order/components/SendGoods/index';
import ChangePrice from '@/views/order/components/ChangePrice/index';
import CloseOrder from '@/views/order/components/CloseOrder/index';
import CancelSendGoods from '@/views/order/components/CancelSendGoods/index';
import InvoiceInfo from '@/views/order/components/InvoiceInfo/index';
// import PaymentTag from "@/components/little/PaymentTag";
import RefundModal from "@/views/order/components/RefundModal";

export default {
	name: "OrderStatus",
	components: {
		EditReceivingGoods,
		SendGoods,
		ChangePrice,
		CloseOrder,
		CancelSendGoods,
		InvoiceInfo,
		// PaymentTag,
		RefundModal,
	},
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			modal: {
				cancelSendGoodsModal: 0,
				editExpress: 0,
				changePrice: 0,
				closeOrder: 0,
				packageExpress: 0,
				invoiceInfo: 0,
				title: '',
				id: '',
				dispatch_type: '',
			},
			image: {
				payment: require('@/assets/image/order/payment.png'),
				put: require('@/assets/image/order/put.png'),
				send: require('@/assets/image/order/send.png')
			},
			invoiceInfo: {
				data: {},
				price: '',
				status: true
			},
			printerList: [],
			templateList: [],
			printerModal: false,
			printerModelData: {
				printer_id: '',
				template_id: '',
				order_id: '',
			},
			rules: {
				printer_id: [
					{ required: true, message: '请输入打印机名称' }
				],
				template_id: [
					{ required: true, message: '请选择打印模板' }
				],
			},
			remark: "",
			is_refund_front: '1',
			virtual: [], //虚拟卡密发货内容展示数据
			deliverModal: false,
			virtual_setting: {
				to_mailer: "",
				use_address: "0",
				use_address_address: "",
				use_address_title: "使用地址",
				use_description: "0",
				use_description_remark: "",
				use_description_title: "使用说明",
			}
		};
	},
	watch: {
		data: {
			handler() {
				this.isCheck();
			}
		}
	},
	computed: {
		// 是否秒杀活动
		is_Seckill() {
			return this.data.activity_type == 2;
		},
		// 发票是否存在
		isInvoiceInfo() {
			let is_company = this.data?.invoice_info?.is_company; // 1个人 2企业
			return is_company && (is_company == '1' || is_company == '2');
		},
		// 收货地址
		addressDetail() {
			return this.data.address_state + this.data.address_city + this.data.address_area + this.data.address_detail;
		},
		// 自动发货
		auto_deliver() {
			return this.data.goods_info?.[0]?.auto_deliver;
		},
		// 自动发货内容
		auto_deliver_content() {
			return this.data.goods_info?.[0]?.auto_deliver_content;
		},
		// 判断是否为虚拟商品
		goods_type() {
			return this.data.goods_info?.[0]?.type;
		},
	},
	methods: {
		// 确认发货
		sendGoods() {
			this.modal = this.data;
			this.$nextTick(() => {
				this.$refs['send_goods'].setValue();
			});
		},
		// 退款
		generalRefund() {
			this.modal.id = this.data.id;
			this.$nextTick(() => {
				this.$refs['refund_modal'].setValue();
			});
		},
		// 修改收货信息
		editReceiveInfo() {
			this.modal.id = this.data.id;
			this.modal.dispatch_type = this.data.dispatch_type;
			this.$nextTick(() => {
				this.$refs['edit_receiving_goods'].setValue();
			});
		},
		// 订单改价
		changePrice() {
			this.modal.id = this.data.id;
			this.$nextTick(() => {
				this.$refs['change_price'].setValue();
			});
		},
		// 关闭订单
		closeOrder() {
			this.$Modal.confirm({
				title: '是否关闭订单',
				okText: '确认关闭',
				onOk: () => {
					let params = {
						id: this.data.id,
						remark: this.remark
					};
					this.$api.orderApi.closeOrder(params)
						.then(res => {
							if (res.error === 0) {
								this.$Message.success('订单关闭成功');
								this.handleRefresh();
							}
						});
				},
				onCancel: () => {
				},
				render: () => {
					return (
						<div>
							<p style="padding-bottom:15px">请填写关闭订单原因</p>
							<Input type="textarea" v-model={this.remark} maxlength="40" show-word-limit autofocus placeholder="请填写关闭订单原因"></Input>
						</div>
					);
				},
			});
		},
		// 取消发货
		cancelSendGoods() {
			// 整单取消
			// 分包裹取消
			this.modal.id = this.data.id;
			this.$nextTick(() => {
				this.$refs['cancel_send_goods'].setValue();
			});
		},
		// 查看发票信息
		packageInvoiceInfo() {
			this.modal.id = this.data.id;
			this.invoiceInfo.data = this.data.invoice_info;
			this.invoiceInfo.price = this.data.pay_price;
			this.$nextTick(() => {
				this.$refs['invoice_info'].setValue();
			});
		},
		// 确认付款
		confirmPayment() {
			this.$Modal.confirm({
				title: '提示',
				content: '确认此单已付款吗？',
				okText: '确认付款',
				onOk: () => {
					let obj = {
						order_id: this.data.id,
					};
					// 进行确认付款操作
					this.$api.orderApi.setPayment(obj)
						.then(res => {
							if (res.error === 0) {
								this.$Message.success('确认付款操作成功');
								this.handleRefresh();
							}
						});
				},
				onCancel: () => {
				}
			});
		},
		// 确认收货
		confirmTakeGoods() {
			this.$Modal.confirm({
				title: '提示',
				content: '确认此单已收到货了吗？',
				okText: '确认收货',
				onOk: () => {
					// 进行确认收货操作
					this.$api.orderApi.setFinishOrder({ order_id: this.data.id })
						.then(res => {
							if (res.error === 0) {
								this.$Message.success('订单确认收货成功');
								this.handleRefresh();
							}
						});
				},
				onCancel: () => {

				}
			});
		},
		handleRefresh() {
			this.$emit('on-refresh');
		},
		// 跳转电子面单打印
		jumpEOrder() {
			let url = `/eOrder/orderPrint/index?order_no=${this.data.order_no}`;
			this.$utils.openNewWindowPage(url);
		},
		// 打印小票
		handlePrinter() {
			this.$api.printerApi.printerList({ only_list: 1, status: 1 }).then(res => {
				if (res.error == 0) {
					if (res.data.length <= 0) {
						this.toPrinterList();
					} else {
						this.printerList = res.data;
						this.checkPrinter(this.data.id);
					}
				}
			});
		},
		toPrinterList() {
			this.$Modal.confirm({
				title: '提示',
				content: '您当前还未配置打印机，立即前往进行配置。',
				okText: '立即配置',
				cancelText: '暂不配置',
				onOk: () => {
					this.$utils.openNewWindowPage('/printer/manage/list');
				},
				onCancel: () => {

				}
			});
		},
		getPrinterTemList() {
			this.$api.printerApi.getPrinterTemList({ only_list: 1 }).then(res => {
				if (res.error == 0) {
					this.templateList = res.data;
					this.printerModal = true;
				}
			});
		},
		checkPrinter(id) {
			this.$api.printerApi.executePrinter({ order_id: id, is_check: 1 }).then(res => {
				if (res.error == 0) {
					this.printerModelData.order_id = id;
					if (res?.message > 0) {
						this.checkPrinterNum(res.message);
					} else {
						this.getPrinterTemList();
					}
				}
			});
		},
		checkPrinterNum(num) {
			this.$Modal.confirm({
				title: '提示',
				content: `您已经打印${num}次，是否继续打印`,
				okText: '立即打印',
				cancelText: '暂不打印',
				onOk: () => {
					this.getPrinterTemList();
				},
				onCancel: () => {

				}
			});
		},
		printerCancel() {
			this.printerModal = false;
		},
		printerOk() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.$api.printerApi.executePrinter(this.printerModelData).then(res => {
						if (res.error == 0) {
							this.$Message.success('打印成功');
						}
						this.printerModal = false;
					});
				}
			});
		},
		// 判断显示发货单和电子面单
		hasOrderSheet(item) {
			return item.icon?.electronic_sheet === 1;
		},
		// 虚拟卡密商品-发货信息展示
		isCheck() {
			this.virtual = this.data?.virtual_account_data || [];
			if (this.virtual.length > 0) {
				this.virtual.forEach((item, index) => {
					this.$set(item, 'ischeck', false);
					let config = JSON.parse(item.config), itemData = JSON.parse(item.data);
					if (config.length > 0) {
						config.forEach((secItem, index) => {
							this.$set(secItem, 'ischeck', false);
							if (itemData[`value${index + 1}`].length > 28) {
								this.$set(item, 'is_wrap', true);
							}
						});
					}
					item.config = config;
					item.data = itemData;
					if (index == 0) {
						this.virtual_setting = { ...item };
					}
				});
			}
		},
		//虚拟卡密发货内容
		deliveryContent() {
			this.deliverModal = true;
		},
		// 卡密商品使用地址 点击跳转
		goAddress(url) {
			if (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) {
				window.open(url);
			} else {
				window.open('http://' + url);
			}
		},
		// 会员跳转
		jumpPage(id) {
			this.$utils.openNewWindowPage('/member/detail', { id: id });
		},
	}
};
</script>

<style scoped lang="scss">
.order-information {
	padding-bottom: 40px;
	align-items: stretch;

	// form 字体调整
	/deep/ .ivu-form {
		//对卡密使用地址特殊处理
		/deep/ .overText {
			.ivu-form-item-label {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.ivu-form-item-label {
			@include font-12-16;
			padding: 0;
		}

		.ivu-form-item-content {
			@include font-12-16;

			// transform: translateY(-4px);
			&:hover {
				.copy-text {
					display: inline-block;
				}
			}

			.copy-text {
				display: none;

				.ivu-btn {
					height: 12px;
					vertical-align: inherit;
				}
			}

			.paragraph {
				@include font-12-18;
				word-break: break-all;

				.logistics {
					li {
						display: flex;
						align-items: center;
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}

						.tag {
							padding: 2px 8px;
							line-height: 16px;
							background-color: #F4F6F8;
							color: #636669;
							margin-right: 4px;
							min-width: 56px;
							text-align: center;
						}

						.number {
							margin-left: 10px;
						}
					}
				}
			}

			.ivu-btn {
				@include font-12-16;
				transform: translateY(-1px);
			}

			.icon-weixin {
				color: $success-color;
			}

			.icon-weixinxiaochengxu {
				color: $channel-weixinxiaochengxu;
			}

			.icon-toutiaoxiaochengxu {
				color: #239BFF;
			}

			.icon-zhifubaoxiaochengxu {
				color: #2094E7;
			}

			.icon-baiduxiaochengxu {
				color: #306CFF;
			}

			.icon-H {
				color: #FF6004;
			}

			// 配送方式
			.dispatch-type {
				align-items: center;

				.dispatch_img {
					height: 18px;
					width: 18px;
				}

				.icon-peisongfangshi-huodaofukuan {
					color: $warning-color;
				}

				.text {
					vertical-align: text-bottom;
					padding-left: 4px;
				}
			}

			.vip-name {
				color: $brand-color;
				cursor: pointer;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: 100%;
				display: block;
			}
		}

		.refund-money-label {
			>.ivu-form-item-label {
				padding-top: 5px;
			}
		}
	}

	.order-no {
		.iconfont {
			font-size: 18px;
		}
	}

	// 未开票
	.no-invoice {
		font-size: 10px;
		font-weight: bold;
		line-height: 16px;
		padding: 0 4px;
		border-radius: 8px;
		background-color: #ff1f2c;
		color: #ffffff;
		margin-left: 10px;

		>span {
			display: inline-block;
			transform: scale(0.85)
		}
	}

	>.status-col {
		padding-left: 20px;
	}

	>.ivu-col {
		padding-right: 30px;
		border-right: 1px solid $border-color;

		.title {
			padding-left: 20px;
		}

		&:last-child {
			border-right: none;
		}
	}

	.title {
		@include font-16-22-bold;
		color: $text-first;
		padding-bottom: 30px;


	}

	.big-title {
		>img {
			/*vertical-align: bottom;*/
			padding-right: 6px;
		}

		>.iconfont {
			font-size: 32px;
			padding-right: 4px;
			line-height: 32px;
		}

		>.title {
			@include font-24-34-bold;
			vertical-align: bottom;
			padding-left: 4px;
			padding-bottom: 0;
		}
	}

	.time-out {
		color: $text-third;
		padding-left: 38px;
	}

	.finish {
		.title {
			color: $success-color;
		}

		.iconfont {
			color: $success-color;
		}
	}

	.close {

		.iconfont,
		.title {
			color: $text-third;
		}
	}

	.status-btn {
		margin: 20px 0 10px 0;
	}

	.footer-btn {
		margin-top: 15px;
		margin-bottom: -10px;

		.ivu-btn {
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.ivu-form {
		.ivu-form-item {
			margin-bottom: 20px;
		}
	}

	.ivu-btn-text {
		margin-left: 10px;
	}

	.iconfont.icon-yingyong-pintuan {
		color: #F66000;
		font-size: 18px;
	}

	.icon-dingdanliebiao-pintuanfanli {
		color: #FF9900;
		font-size: 18px;
	}

	.seckill-icon {
		font-size: 18px;
		color: #ff1f2c;
	}

	.icon-dingdanliebiao-jifen {
		font-size: 18px;
		color: #6100FF;
	}
}

.printer-modal {
	padding: 30px 0;
}

.goAddress {
	font-size: 12px;
	color: #2d8cf0;
	margin-top: 2px;
	margin-left: 10px;
	cursor: pointer;
}
</style>
