<template>
    <kdx-modal-frame v-model="value" title="发票信息" :width="700" okText="确认开票" :cancelText="cancelText" :isOk="isOk"
        @on-cancel="handleCancel" @on-ok="handleOk">
        <Form ref="form" :label-width="100" class="order-invoice-info">
            <FormItem label="发票类型：" class="r-form-item-text">
                <span v-if="data.is_electronic == '1' || data.is_electronic == '0'
                    ">纸质发票</span>
                <span v-else-if="data.is_electronic == '2'">电子发票</span>
            </FormItem>
            <FormItem label="抬头类型：" class="r-form-item-text">
                <span v-if="data.is_company == '1'">个人</span>
                <span v-else-if="data.is_company == '2'">企业</span>
                <span v-else>-</span>
            </FormItem>
            <FormItem label="发票抬头：" class="r-form-item-text">
                <span>{{ data.title }}</span>
            </FormItem>
            <FormItem label="发票金额：" class="r-form-item-text">
                <span class="money">￥{{ price }}</span>
            </FormItem>
            <FormItem label="税号：" class="r-form-item-text">
                <span>{{ data.number }}</span>
            </FormItem>
            <FormItem label="邮箱地址：" class="r-form-item-text" v-if="data.is_electronic == '2'">
                <span>{{ data.address || '-' }}</span>
            </FormItem>
        </Form>
    </kdx-modal-frame>
</template>

<script>
export default {
    name: 'index',
    props: {
        data: {
            type: Object,
            default: () => { }
        },
        price: {
            type: [Number, String]
        },
        id: {
            type: [Number, String]
        }
    },
    data() {
        return {
            value: false,
            model: {},
            cancelText: '取消',
            isOk: true
        };
    },
    computed: {
    },
    methods: {
        setValue() {
            this.value = !this.value;
            if (this.value) {
                if (this.data.status == '1') {
                    this.isOk = false;
                    this.cancelText = '关闭';
                } else {
                    this.isOk = true;
                    this.cancelText = '取消';
                }
            }
        },
        handleCancel() {
            this.setValue();
        },
        handleOk() {
            this.$api.orderApi
                .changeInvoiceStatus({ order_id: this.id })
                .then(res => {
                    if (res.error === 0) {
                        this.$Message.success('开票成功');
                        this.setValue();
                        this.$emit('on-refresh');
                    }
                });
        }
    }
};
</script>

<style scoped lang="scss">
.order-invoice-info {
    padding: 20px 40px;

    .money {
        @include font-18-25-bold;
        color: $warning-color;
        line-height: 20px;
    }
}
</style>
