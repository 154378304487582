import { render, staticRenderFns } from "./OrderDetailList.vue?vue&type=template&id=50f39fea&scoped=true"
import script from "./OrderDetailList.vue?vue&type=script&lang=js"
export * from "./OrderDetailList.vue?vue&type=script&lang=js"
import style0 from "./OrderDetailList.vue?vue&type=style&index=0&id=50f39fea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f39fea",
  null
  
)

export default component.exports