<template>
	<div class="order-detail">
		<!--订单状态---步骤-->
		<Card shadow :bordered="false">
			<Steps :current="current" :status="step.status">
				<Step v-for="(item, index) in stepList" :key="index" :title="item.title" :content="item.content"></Step>
			</Steps>
		</Card>
		<div class="order-detail-content">
			<order-status :data="data" @on-refresh="getData"></order-status>
		</div>
		<div class="order-detail-con">
			<ul class="detail_tab">
				<li class="tab" @click="clickTab(0)" :class="{ active: 0 == clickIndex }">商品信息</li>
			</ul>
			<!--表单信息 isShow 控制表头显示-->
			<form-info v-if="form.content && clickIndex == 2" :data="form.content" :isShow="false"></form-info>
			<order-detail-list :data="data" @on-refresh="getData" v-if="clickIndex == 0"></order-detail-list>
		</div>
		<slot></slot>
	</div>
</template>

<script>
import OrderStatus from './components/OrderStatus';
import OrderDetailList from '@/views/order/components/OrderDetailList';
import FormInfo from '@/components/form/FormInfo';

import { dateFormat } from '@/helpers/common';

export default {
	// 订单状态
	name: 'index',
	components: {
		OrderStatus,
		OrderDetailList,
		FormInfo,
	},
	data() {
		return {
			current: 0,
			step: {
				lastTitle: '订单完成',
				lastIcon: '',
				status: 'finish'
			},
			data: {},
			form: {},
			id: '',
			stepList: [],
			orderNo: '',
			member_id: '',
			commission_info: {
				'1': {
					commission: '0',
					level: '1',
					nickname: ""
				},
				'2': {
					commission: '0',
					level: '2',
					nickname: ""
				}
			},
			clickIndex: 0, //tab选中
		};
	},
	methods: {
		initStep() {
			let stepList = [
				{
					title: '用户核销',
					content: this.data.grantTime ? dateFormat('YY-mm-dd HH:MM:SS', new Date(this.data.grantTime * 1000)) : '',
					show: true
				},
				{
					title: '商家发货',
					content: this.data.sendTime ? dateFormat('YY-mm-dd HH:MM:SS', new Date(this.data.sendTime * 1000)) : '',
					show: true
				},
				// {
				//     title: '订单完成',
				//     content: this.getTime(this.data.finish_time),
				//     show: this.data?.status != -1

				// },
				// {
				//     title: '订单关闭',
				//     content: this.getTime(this.data.finish_time),
				//     show: this.data?.status == -1
				// },
			];
			this.stepList = stepList.filter(item => item.show);
			this.getCurrent();
		},
		getCurrent() {
			// 订单状态 0代付款 10待发货 11部分发货 20待收货 30已完成 -1订单关闭
			switch (this.data.isSend * 1) {
				case 1:
					this.current = 2;
					break;
				case 0:
				default:
					this.current = 1;
					break;
			}
		},
		async getData() {
			const { info } = await this.$api.goods.prizedetails({ sid: this.id });
			this.data = info;
			// let res = {
			// 	"error": 0,
			// 	"id": "224",
			// 	"order_type": "10",
			// 	"activity_type": "0",
			// 	"order_no": "1020230506113612888778",
			// 	"trade_no": "TR20230506113613701460",
			// 	"out_trade_no": "-1",
			// 	"member_id": "1182",
			// 	"status": "30",
			// 	"close_type": "0",
			// 	"original_price": "7.90",
			// 	"pay_type": "2",
			// 	"pay_price": "7.90",
			// 	"goods_price": "7.90",
			// 	"original_goods_price": "7.90",
			// 	"goods_info": [
			// 		{
			// 			"title": "旺旺牛奶",
			// 			"short_name": "",
			// 			"sub_name": "",
			// 			"option_title": "",
			// 			"thumb": "image/2023/05/efba56de320996e308b50835e92389d6.jpg",
			// 			"option_thumb": null,
			// 			"goods_sku": "",
			// 			"unit": "件",
			// 			"type": "0",
			// 			"goods_id": "24",
			// 			"stock": "99",
			// 			"option_id": "0",
			// 			"total": "1",
			// 			"price": 7.9,
			// 			"price_unit": "7.90",
			// 			"price_original": 7.9,
			// 			"price_discount": 0,
			// 			"extra": null,
			// 			"ext_field": {
			// 				"show_sales": "0",
			// 				"show_stock": "1",
			// 				"invoice": "0",
			// 				"refund": "1",
			// 				"return": "1",
			// 				"exchange": "1",
			// 				"is_delivery_pay": "0",
			// 				"auto_putaway": "0",
			// 				"single_max_buy": "",
			// 				"single_min_buy": "",
			// 				"max_buy": "",
			// 				"putaway_time": "",
			// 				"note": "",
			// 				"buy_button_settings": [],
			// 				"buy_button_type": "0",
			// 				"buy_limit": "0"
			// 			},
			// 			"auto_deliver": "0",
			// 			"auto_deliver_content": "",
			// 			"plugin_identification": {
			// 				"is_commission": "1"
			// 			},
			// 			"activity_package": [],
			// 			"form_data": null,
			// 			"form_status": "0",
			// 			"form_id": "0",
			// 			"virtual_account_id": null,
			// 			"virtual_account_mailer_setting": null,
			// 			"order_goods_id": 227
			// 		}
			// 	],
			// 	"address_state": "山东省",
			// 	"address_city": "青岛市",
			// 	"address_area": "市北区",
			// 	"address_code": "370203",
			// 	"address_detail": "延吉路116号",
			// 	"address_info": "{\"dispatch_type\":10,\"address_id\":\"140\",\"province\":\"山东省\",\"city\":\"青岛市\",\"area\":\"市北区\",\"area_code\":\"370203\",\"address_detail\":\"延吉路116号\",\"lng\":\"120.379084\",\"lat\":\"36.088356\",\"name\":\"炸毛\",\"postcode\":0}",
			// 	"agent_id": "0",
			// 	"created_at": "2023-05-06 11:36:12",
			// 	"pay_time": "2023-05-06 11:36:13",
			// 	"send_time": "2023-05-06 11:37:03",
			// 	"finish_time": "2023-05-06 11:37:20",
			// 	"cancel_time": "0000-00-00 00:00:00",
			// 	"cancel_reason": "",
			// 	"buyer_name": "炸毛",
			// 	"buyer_mobile": "13245678910",
			// 	"refund_type": "0",
			// 	"is_refund": "0",
			// 	"buyer_remark": "",
			// 	"user_delete": "0",
			// 	"member_nickname": "Whaoe",
			// 	"member_realname": "",
			// 	"member_mobile": "",
			// 	"change_price": "0.00",
			// 	"change_price_count": "0",
			// 	"dispatch_info": "[null]",
			// 	"dispatch_type": "10",
			// 	"dispatch_price": "0.00",
			// 	"change_dispatch": "0.00",
			// 	"original_dispatch_price": "0.00",
			// 	"create_from": "20",
			// 	"remark": "",
			// 	"cost_price": "0.00",
			// 	"auto_finish_time": "2023-05-13 11:37:03",
			// 	"auto_close_time": "2023-05-06 12:36:12",
			// 	"extra_discount_rules_package": [],
			// 	"extra_price_package": [],
			// 	"extra_package": {
			// 		"delivery_time": "",
			// 		"article_id": 0
			// 	},
			// 	"invoice_info": [],
			// 	"extra_pay_price": "",
			// 	"refund_price": "0.00",
			// 	"is_count": "1",
			// 	"scene": "0",
			// 	"scene_value": "0",
			// 	"seller_remark": "",
			// 	"refund_reason": "",
			// 	"is_bill_print": "0",
			// 	"orderGoods": [
			// 		{
			// 			"id": "227",
			// 			"order_id": "224",
			// 			"goods_id": "24",
			// 			"option_id": "0",
			// 			"member_id": "1182",
			// 			"status": "30",
			// 			"title": "旺旺牛奶",
			// 			"short_name": "",
			// 			"option_title": "",
			// 			"thumb": "image/2023/05/efba56de320996e308b50835e92389d6.jpg",
			// 			"price": "7.90",
			// 			"price_original": "7.90",
			// 			"price_unit": "7.90",
			// 			"price_discount": "0.00",
			// 			"price_change": "0.00",
			// 			"total": "1",
			// 			"dispatch_info": "{\"type\":\"0\",\"template_id\":\"0\",\"rules\":[],\"exec_price\":\"0.00\"}",
			// 			"is_single_refund": "0",
			// 			"package_cancel_reason": "",
			// 			"package_id": "3",
			// 			"goods_sku": "",
			// 			"bar_code": "",
			// 			"refund_type": "0",
			// 			"refund_status": "0",
			// 			"created_at": "2023-05-06 11:36:12",
			// 			"comment_status": "0",
			// 			"activity_package": "[]",
			// 			"pay_time": "2023-05-06 11:36:13",
			// 			"is_count": "1",
			// 			"plugin_identification": {
			// 				"is_commission": "1"
			// 			},
			// 			"weight": "0.00",
			// 			"is_print": "0",
			// 			"cost_price": "0.00",
			// 			"is_deleted": "0",
			// 			"shop_goods_id": "0",
			// 			"shop_option_id": "0",
			// 			"ext_field": {
			// 				"show_sales": "0",
			// 				"show_stock": "1",
			// 				"invoice": "0",
			// 				"refund": "1",
			// 				"return": "1",
			// 				"exchange": "1",
			// 				"is_delivery_pay": "0",
			// 				"auto_putaway": "0",
			// 				"single_max_buy": "",
			// 				"single_min_buy": "",
			// 				"max_buy": "",
			// 				"putaway_time": "",
			// 				"note": "",
			// 				"buy_button_settings": [],
			// 				"buy_button_type": "0",
			// 				"buy_limit": "0"
			// 			},
			// 			"refund_status_text": "",
			// 			"type": "0",
			// 			"auto_deliver": "0",
			// 			"auto_deliver_content": ""
			// 		}
			// 	],
			// 	"pay_type_text": "余额支付",
			// 	"dispatch_type_text": "快递",
			// 	"status_text": "已完成",
			// 	"create_from_text": "微信公众号",
			// 	"type_text": "普通订单",
			// 	"package_info": [
			// 		{
			// 			"express_sn": "554556526599889000",
			// 			"express_com": "顺丰",
			// 			"express_name": ""
			// 		}
			// 	],
			// 	"send_type": 0,
			// 	"icon": {
			// 		"electronic_sheet": 1
			// 	},
			// 	"commission_info": {
			// 		"1": {
			// 			"nickname": "Whaoe",
			// 			"id": "1182",
			// 			"avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/N1AUQvFo1sGcd0HeAsicC95ibwGVbMfon2SsEhwbrGicKicQ1tg2W3ZokEj9Sd5Aib7R7KhVtkexzUd83bFnDZTtYUA/132",
			// 			"level": "1",
			// 			"commission": "0.03",
			// 			"ladder_commission": "0.00"
			// 		}
			// 	},
			// 	"form": []
			// };


			this.initStep();

		},
		// 切换tab
		clickTab(i) {
			this.clickIndex = i;
		}
	},
	watch: {
		'$route.path': {
			handler() {
				this.id = this.$route.query.id;
				this.getData();
			},
			immediate: true
		}
	}
};
</script>

<style scoped lang="scss">
.order-detail {
	background-color: $background-color;
	margin: 20px auto;

	.ivu-card {
		box-shadow: none;
	}

	.order-detail-con {
		background-color: #ffffff;
		margin-top: 10px;

		.detail_tab {
			display: flex;
			flex-direction: row;
			height: 49px;
			line-height: 50px;
			border-bottom: 1px solid #E9EDEF;

			.tab {
				font-size: 18px;
				color: #262b30;
				font-weight: 600;
				margin-left: 60px;
				position: relative;
				cursor: pointer;

				&:first-child {
					margin-left: 38px;
				}

				&.active {
					color: #2d8cf0;

					&:after {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 3px;
						background-color: #2d8cf0;
					}
				}
			}
		}
	}

	.order-detail-content {
		background-color: #ffffff;
		margin-top: 10px;
		padding: 30px 40px;
	}

	/deep/ .ivu-card-body {
		padding: 30px;

		.ivu-steps {
			padding: 0 130px;
		}
	}
}
</style>
