<template>
	<div class="order-detail-table-list">
		<Table ref="table" v-if="data.shopInfo" :columns="columns" :data="[data]" class="table-form">
		</Table>
		<div class="shop-total">
			<div class="shop-total-box" v-for="(item, index) in priceList" :key="index">
				<div class="shop-total-content">
					<div class="label">{{ item.title }}</div>
					<div class="number" :class="{ 'red': item.color }">{{ item.price }}</div>
				</div>
			</div>
			<!--实付款-->
			<div class="shop-total-box">
				<div class="shop-total-content payment">
					<div class="label">实付款：</div>

					<!-- 普通 -->
					<div class="number price">￥{{ data.money }}</div>
				</div>
			</div>
		</div>
		<!--修改快递-->
		<edit-express ref="edit_express" :id="modal.id" :package-id="modal.packageId"></edit-express>
		<!--查看物流-->
		<package-express ref="package_express" :id="modal.id" :package-id="modal.packageId"></package-express>
		<!-- 查看备注 -->
		<remark-modal v-model="remarkModal" :content="remarkContent"></remark-modal>
	</div>
</template>

<script>
import EditExpress from '@/views/order/components/EditExpress/index';
import PackageExpress from '@/views/order/components/PackageExpress/index';
import RemarkModal from '@/views/order/components/RemarkModal/index';

export default {
	name: 'TableList',
	components: { EditExpress, PackageExpress, RemarkModal },
	props: {
		data: {
			type: Object,
			default: () => {
			}
		},
		isRefund: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			columns: [
				{   // 商品标题
					title: '商品标题',
					key: 'title',
					minWidth: 240,
					render: (h, params) => {
						return (
							<div class="shop-title">
								<div class="image">
									<img src={params.row.shopInfo.pic} onError={e => {
										this.replaceImage(e);
									}} />
								</div>
								<div class="content">

									<span class="text">
										<span class={[this.getType.goodsName, 'mark']}>
											{this.getType.goodsText}
										</span>
										{params.row.shopInfo.title}
									</span>
									{/* {label} */}
								</div>
							</div>
						);
					}
				},
				{   // 售卖价
					title: '单价',
					key: 'money',
					minWidth: 120,
					render: (h, params) => {
						return h('div', `￥${params.row[params.column.key]}`);
					}
				},
				// {
				// 	title: '数量',
				// 	key: 'total',
				// 	minWidth: 80,
				// 	render: (h, params) => {
				// 		return h('div', `X${params.row[params.column.key]}`);
				// 	}
				// },
				// {
				// 	title: '优惠金额',
				// 	key: 'price_discount',
				// 	minWidth: 100,
				// 	render: (h, params) => {
				// 		return h('div', `￥${params.row[params.column.key]}`);
				// 	}
				// },
				// {
				// 	title: '小计',
				// 	key: 'price_original',
				// 	minWidth: 120,
				// 	render: (h, params) => {
				// 		if (params.row?.credit) {
				// 			if (!params.row.price_original) return h('div', `${params.row.credit} 积分`);
				// 			return h('div', `${params.row.credit} 积分 + ￥${params.row.price_original}`);
				// 		}
				// 		// 单价
				// 		let price = parseFloat(params.row.price_unit) || '0';
				// 		let total = parseInt(params.row.total) || '0';
				// 		let price_original = (price * total).toFixed(2);
				// 		return h('div', `￥${price_original}`);
				// 	}
				// },
				{
					title: '状态',
					key: 'status',
					minWidth: 80,
					maxWidth: 150,
					render: (h, params) => {

						//订单状态 0代付款 10待发货 11部分发货 20待收货 30已完成
						const status = params.row.isSend;
						const style1 = { color: '#ff9900', display: status == '0' ? 'inline-block' : 'none' };
						const style4 = { color: '#19BE6B', display: status == '1' ? 'inline-block' : 'none' };
						return (
							<div>
								<span
									style={style1}
								>
									待发货
								</span>
								<span
									style={style4}
								>
									已完成
								</span>
							</div>
						);
					}
				},
			],
			total: {
				subtotal: 1000 //小计
			},
			modal: {
				editExpress: 0,
				packageId: '',
				packageExpress: 0,
				id: ''
			},
			colTitle: '维权金额',
			remarkModal: false,
			remarkContent: ''
		};
	},
	computed: {
		getType() {
			// type: 0实体 1虚拟 
			let type;
			switch (this.data.shopType + '') {
				case '1':
					type = {
						goodsName: 'real',
						goodsText: '实'
					};
					break;

				case '2':
					type = {
						goodsName: 'virtual',
						goodsText: '虚'
					};
					break;
				default:
					type = {};
					break;
			}
			return type;
		},
		priceList() {
			let result = [];
			let goodsInfo = this.data.goods_info, goods_type;
			if (goodsInfo && goodsInfo.length > 0) {
				goods_type = goodsInfo[0].type;
			}
			result = [

				// {
				// 	title: `运费：`,
				// 	price: `￥${this.data?.dispatch_price}`,
				// 	show: +this.data.dispatch_type !== 20 && +goods_type === 0,
				// 	color: false
				// },
			];

			result = result.filter(item => item.show);
			return result;
		},

		isRefundSuc() {
			return this.data.orderGoods.every(
				v => v.refund_status == 10 || v.refund_status == 11
			);
		},
	},
	created() { },
	methods: {
		formatPrice(price) {
			if (parseFloat(price) >= 0) {
				return `￥${parseFloat(price).toFixed(2)}`;
			} else {
				return `-￥${Math.abs(parseFloat(price)).toFixed(2)}`;
			}
		},

		viewRemark(data) {
			// 查看备注
			this.remarkModal = true;
			this.remarkContent = data;
		}
	},
};
</script>

<style lang="scss" scoped>
.order-detail-table-list {
	color: $text-first;
	padding: 40px 40px 0;



	.table-form {

		/deep/ .ivu-table {
			.ivu-table-body,
			.ivu-table-header {
				tr {
					th {
						background: #F4F6F8;

						&:nth-last-child(2) {

							/*background-color: red;*/
							.ivu-table-cell {
								padding: 0; // 适配表单
							}
						}
					}
				}
			}

			.ivu-table-expanded-cell {
				background-color: $brand-hover;
				padding-left: 0;
			}

			.ivu-table-body {
				font-size: 12px;
			}
		}
	}

	/deep/ .ivu-table-cell {
		>div {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&.ivu-table-cell-with-expand {
			display: none;
		}

		.action-btn {
			/*word-wrap:break-word;*/
			/*word-break:normal;*/
			white-space: inherit;
			margin-right: -10px;

			.ivu-btn {
				padding-right: 10px;
			}
		}

		// 商品标题
		.shop-title {
			display: flex;
			overflow: inherit;
			text-overflow: inherit;
			white-space: inherit;
			align-items: center;

			>.image {
				width: 80px;
				height: 80px;

				>img {
					width: 80px;
					height: 80px;
					border: 1px solid $border-color;
					border-radius: 2px;
					box-sizing: border-box;
				}
			}

			>.content {
				margin-left: 10px;

				.mark {
					@include font-12-16;
					border-radius: 2px;
					width: 18px;
					height: 18px;
					text-align: center;
					line-height: 18px;
					display: inline-block;

					&.real {
						background-color: #f0faff;
						color: $brand-color;
					}

					&.virtual {
						background-color: #e6fff9;
						color: #00c5c5;
					}

					&.secret {
						background-color: #FFCDD0;
						color: #FF000F;
					}

					&.credit_shop_coupon {
						background-color: #FFF9E6;
						color: #FF9900;
					}
				}

				>.text {
					/*margin-top: 10px;*/
					@include font-12-16;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					max-height: 60px;
					word-break: break-all;
				}

				>.shop-label {
					margin-bottom: -10px;

					.tag-label {
						margin-bottom: 10px;

						&:first-child {
							margin-right: 10px;
						}

						.label-warning {
							span {
								color: $warning-color;
							}

							.iconfont {
								padding-right: 2px;
								vertical-align: bottom;
							}
						}
					}
				}
			}

		}

		// 规格/编码/条码
		.shop-size {
			@include font-12-16;

			>p {
				padding-bottom: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				>.label {
					padding-right: 10px;
				}

				>.specification,
				.coupon {
					color: #197be1;
					background-color: #f0faff;
					padding: 2px 5px;
					font-size: 12px;
				}
			}
		}

		.ivu-btn.ivu-btn-text {
			font-size: 12px;
		}
	}

	// 商品总计
	.shop-total {
		padding: 40px 0;

		>.shop-total-box {
			display: flex;
			justify-content: flex-end;

			>.shop-total-content {
				display: flex;
				align-items: center;
				color: $text-first;
				@include font-14-20;
				padding-bottom: 10px;

				>.label {
					width: 120px;
					text-align: right;
					margin-right: 5px;
				}

				>.number {
					width: 190px;
					text-align: right;
				}

				>.red {
					color: $danger-color;
				}
			}

			>.payment {
				@include font-14-20-bold;
				padding-top: 10px;

				>.price {
					@include font-18-25-bold;
					color: $danger-color;
				}
			}
		}
	}
}
</style>
